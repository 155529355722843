import { BigNumber, Contract, providers, utils } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";

export const _isMetaMaskInstalled = () => {
    if (typeof window === "undefined") return;
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
};

export const _getProvider = () => {
    if (!_isMetaMaskInstalled()) return null;
    return new providers.Web3Provider(window.ethereum);
};

export const _getChain = async () => {
    const provider = _getProvider();
    if (!provider) return -1;
    return `${(await provider.getNetwork()).chainId}`;
};

const _onAccountsChanged = (callback) => {
    if (!_isMetaMaskInstalled()) return;
    window.ethereum.on("accountsChanged", callback);
};

const _onChainChanged = (callback) => {
    if (!_isMetaMaskInstalled()) return;
    window.ethereum.on("chainChanged", callback);
};

export const _getAddress = async () => {
    const provider = _getProvider();
    if (!provider) return null;
    try {
        const accounts = await provider.listAccounts();
        return accounts.length > 0 ? accounts[0] : null;
    } catch (e) {
        return null;
    }
};

export const useWallet = () => {
    const [wallet, setWallet] = useState(null);
    const [chain, setChain] = useState(-1);

    useEffect(() => {
        const load = async () => {
            try {
                setWallet((await _getAddress())?.toLowerCase());
                setChain(await _getChain());
            } catch (error) {
                return error;
            }
        };

        _onAccountsChanged((_address) => {
            if (!_address[0]) return;
            setWallet(_address[0].toLowerCase());
        });
        _onChainChanged((_chain) => {
            if (!_chain) return;
            setChain(`${parseInt(_chain)}`);
        });
        load();
    }, []);

    return {
        wallet,
        chain,
    };
};

export const connectMetamask = async () => {
    if (!_isMetaMaskInstalled()) return false;
    try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        return true;
    } catch (e) {
        /* console.log(e); */
        return false;
    }
};

export const switchToMainnet = async () => {
    if (!_isMetaMaskInstalled()) return false;
    try {
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
                {
                    chainId: `0x${parseInt(process.env.REACT_APP_CHAIN).toString(16)}`,
                },
            ],
        });
        return true;
    } catch (e) {
        /* console.log(e); */
        return false;
    }
};

export const watchTransaction = (txHash, callback) => {
    const provider = _getProvider();
    if (!provider) return;
    provider.once(txHash, (transaction) => {
        callback(transaction, transaction.status === 1);
    });
};

export const parseBigNumber = (bn, decimalsOrUnitName = "mwei") => {
    if (!bn) return 0;
    try {
      return parseFloat(utils.formatUnits(bn, decimalsOrUnitName));
    } catch (e) {
      return bn;
    }
  };

/* function getContract() {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");

    const signer = provider.getSigner();
    return new Contract(process.env.REACT_APP_GAME_CONTRACT, gameAbi, signer);
} */

export const addUSDCE = async () => {
    try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        return await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664", // The address that the token is at.
                    symbol: "USDCe", // A ticker symbol or shorthand, up to 5 chars.
                    decimals: 6, // The number of decimals in the token
                    image: "https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=022", // A string url of the token logo
                },
            },
        });
    } catch (error) {
        return false;
    }
}