import ContainerWithBgImage from "../components/ContainerWithBgImage";
import ImgNextGen from "../components/ImgNextGen";
import firebirds_lg from "../assets/images/firebirds_lg.png";
import firebirdsWebp_lg from "../assets/images/firebirds_lg.webp";
import firebirdsJp2_lg from "../assets/images/firebirds_lg.jp2";
import firebirdsJxr_lg from "../assets/images/firebirds_lg.jxr";
import useMediaQuery from "../hooks/useMediaQuery";

export default function Footer({ refFooter, setScrollTo }) {
    const bp320px = useMediaQuery(320);
    const bp640px = useMediaQuery(640);
    const bp1024px = useMediaQuery(1024);

    const buttons = [
        <>
            <span className="mr-1">Back to top</span>
            <svg width="6" height="17" viewBox="0 0 6 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 0.5L0.113248 5.5L5.88675 5.5L3 0.5ZM3.5 16.5L3.5 5L2.5 5L2.5 16.5L3.5 16.5Z" fill="#CCCCCC" />
            </svg>

        </>,
        "About",
        "Tokenomics",
        "Rewards",
        "Giveaways",
        "F.A.Q.",
    ];

    return (
        <footer ref={refFooter} className={"bg-primeblack bg-opacity-50"}>
            <ContainerWithBgImage bgImg={"footerBg"} className={"bg-primeblack bg-opacity-50"} subContainerNotNeeded paddingBottom={20}>
                <div className="container mx-auto px-5 flex justify-center">
                    <div>
                        <div className="flex justify-center mb-24">
                            <ImgNextGen
                                srcWebp={firebirdsWebp_lg}
                                srcJp2={firebirdsJp2_lg}
                                srcJxr={firebirdsJxr_lg}
                                fallback={firebirds_lg}
                                width={bp640px ? 365 : bp320px ? 280 : "100%"}
                                height={bp640px ? 64 : bp320px ? 49.09 : "auto"}
                                alt={"Firebirds"}
                            />
                        </div>
                        <div className="flex justify-center mb-28">
                            <nav className={bp1024px ? "flex" : "w-fit"}>
                                {buttons.map((button, i) => {
                                    return (
                                        <div key={i.toString()} className={bp1024px ? "flex justify-center" : ""}>
                                            <div className="flex justify-center">
                                                <button
                                                    className="text-18 font-light opacity-40 hover:opacity-100 transition duration-300 flex items-center"
                                                    onClick={() => setScrollTo(i)}
                                                >
                                                    {button}
                                                </button>
                                            </div>
                                            {i < buttons.length - 1 &&
                                                <div className={
                                                    bp1024px
                                                        ? "w-[1px] h-full bg-bordergray mx-8"
                                                        : "w-full h-[1px] bg-bordergray my-4"}
                                                />}
                                        </div>
                                    )
                                })}
                            </nav>
                        </div>
                        <p className="text-16 font-medium tracking-[-0.02em] text-center">© All rights reserved | Firebirds 2022</p>
                    </div>
                </div>
            </ContainerWithBgImage>
        </footer>
    )
}