import classes from "../design/Ripple.module.scss";
import Loading from "./Loading/Loading";

export default function CustomBtn({
    type = "filled",
    size = "lg",
    className,
    asLink,
    loading,
    disabled,
    onClick,
    children,
    ...rest
}) {
    const typeStyles =
        type === "filled"
            ? `bg-gray
                text-black 
                border-4 border-[#afafaf] border-solid 
                ${!loading && !disabled && "btnShadow"}
                ${!loading && !disabled && classes.rippleForFilledBtn}`
            : `bg-transparent
                text-gray ${!loading && !disabled && "hover:text-black focus:text-black"}
                border border-gray border-solid ${!loading && !disabled && "focus:bg-gray"}
                ${!loading && !disabled && classes.rippleForOutlineBtn}`;

    const sizeStyles =
        size === "lg"
            ? `rounded-[30px] px-[40px] py-[6px] tracking-wider text-22`
            : `rounded-[25px] px-[20px] py-[6px]`;

    const baseStyles = `flex justify-center items-center ${(loading || disabled) ? "opacity-50 cursor-default" : "cursor-pointer"}`;

    if (asLink)
        return (
            <a className={`${baseStyles} ${typeStyles} ${sizeStyles} ${className}`} {...rest}>
                {loading ? <Loading color={type !== "filled" ? "gray" : ""} size={size} /> : children}
            </a>
        );
    else
        return (
            <button
                className={`${baseStyles} ${typeStyles} ${sizeStyles} ${className}`}
                onClick={!loading && !disabled ? () => onClick() : undefined}
                {...rest}
            >
                {loading ? <Loading color={type !== "filled" ? "gray" : ""} size={size} /> : children}
            </button>
        )
}