import classes from "../design/Ripple.module.scss";

export default function OpaqueBtn({
    isActive = false,
    disabled,
    className = "",
    children,
    ...rest
}) {
    return (
        <button
            className={`flex items-center justify-center
                    hover:bg-gray hover:bg-opacity-[0.15]
                    ${isActive ? `bg-gray bg-opacity-[0.15] ${disabled ? "opacity-70" : "opacity-100"}` : "opacity-40"}
                    ${!disabled && "hover:opacity-100"}
                    ${disabled ? "cursor-default" : "cursor-pointer"}
                    ${!disabled && classes.rippleForNavigationBtn}
                    ${className}`}
            {...rest}
        >
            {children}
        </button>
    )
}