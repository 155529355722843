import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from "react";
import { CSSTransition } from 'react-transition-group';

const ImgNextGen = ({
    srcWebp,
    srcJxr,
    srcJp2,
    alt,
    fallback,
    src,
    sizes,
    domColor,
    width,
    height,
    lazyLoadNotNeeded,
    className,
    imgClassName,
    ...props
}) => {
    const { ref, inView, entry } = useInView({
        threshold: 0.1,
        rootMargin: "500px"
    });
    const [isLoaded, setIsLoaded] = useState(lazyLoadNotNeeded ? true : false);

    useEffect(() => {
        if (inView) setIsLoaded(true);
    }, [inView]);

    return (
        <CSSTransition in={isLoaded} timeout={1000} classNames="fade">
            <div ref={ref} className={className} style={{ width: width, height: height, backgroundColor: !isLoaded ? domColor : null }}>
                <picture>
                    {isLoaded
                        ?
                        <>
                            <source srcSet={srcWebp} type="image/webp" sizes={sizes} />
                            <source srcSet={srcJxr} type="image/jxr" sizes={sizes} />
                            <source srcSet={srcJp2} type="image/jp2" sizes={sizes} />
                            <source
                                srcSet={fallback}
                                type={`image/${fallback.slice(fallback.lastIndexOf(".") + 1, fallback.length) === "png" ? "png" : "jpeg"}`}
                                sizes={sizes}
                            />
                            <img
                                src={src || fallback}
                                alt={alt}
                                sizes={sizes}
                                width={width}
                                height={height}
                                loading={lazyLoadNotNeeded ? null : 'lazy'}
                                className={imgClassName}
                                {...props}
                            />
                        </>
                        :
                        <></>
                    }
                </picture>
            </div>
        </CSSTransition>
    );
};

export default ImgNextGen;