import useMediaQuery from "../../hooks/useMediaQuery";
import classes from "./Loading.module.scss";

export default function Loading({ color, size }) {
    const bp640px = useMediaQuery(640);

    return (
        <div
            className={classes.ldsRing}
            style={{
                width: size === "sm" ? 23 : bp640px ? 30 : 20,
                height: size === "sm" ? 23 : bp640px ? 30 : 20,
            }}
        >
            {[0, 1, 2, 3].map(d => {
                return (
                    <div
                        key={d.toString()}
                        className={`${color === "gray" ? "border-t-gray" : "border-t-black"} border-r-transparent border-b-transparent border-l-transparent`}
                        style={{
                            width: size === "sm" ? 23 : bp640px ? 30 : 20,
                            height: size === "sm" ? 23 : bp640px ? 30 : 20,
                        }}
                    />
                )
            })}
        </div>
    )
}