import { lazy, Suspense, useState } from "react";
import Header from "./components/Header";
import LandingPage from "./pages/LandingPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContainerWithBgImage from "./components/ContainerWithBgImage";
import Loading from "./components/Loading/Loading";

const MintPage = lazy(() => import("./pages/MintPage"));
const ClaimPage = lazy(() => import("./pages/ClaimPage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));

function App() {
  const [activeButton, setActiveButton] = useState(0);
  const [scrollTo, setScrollTo] = useState(0);

  const Fallback = () => {
    return (
      <div className={"bg-primeblack bg-opacity-50"}>
        <ContainerWithBgImage
          bgImg={"heroBg"}
          className={"bg-primeblack bg-opacity-50 min-h-[100vh]"}
          subContainerNotNeeded
          paddingTop={200}
        >
          <div className="container mx-auto px-5 flex justify-center">
            <Loading color={"gray"} />
          </div>
        </ContainerWithBgImage>
      </div>
    )
  }

  return (
    <div>
      <Router>
        <Header activeButton={activeButton} setScrollTo={setScrollTo} />
        <Routes>
          <Route
            path="/"
            exact
            element={
              <LandingPage
                activeButton={activeButton}
                setActiveButton={setActiveButton}
                scrollTo={scrollTo}
                setScrollTo={setScrollTo}
              />
            }
          />
          <Route path="/mint" element={<Suspense fallback={<Fallback />}><MintPage /></Suspense>} />
          <Route path="/claim" element={<Suspense fallback={<Fallback />}><ClaimPage /></Suspense>} />
          <Route path="*" element={<Suspense fallback={<Fallback />}><NotFoundPage /></Suspense>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
