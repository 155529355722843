import { useEffect, useRef, useState } from "react";

export default function CustomTooltip({
    tooltip,
    onInvisible,
    position,
    children,
    ...rest
}) {
    const refContainer = useRef();
    const refTooltip = useRef();

    const [visible, setVisible] = useState(false);
    const [positionCss, setPositionCss] = useState();
    const [triangle, setTriangle] = useState();
    const [minWidth, setMinWidth] = useState(0);
    const [minHeight, setMinHeight] = useState(0);

    const toTop = (tooltipHeight, triangleSize, triangleLeft, left) => {
        setPositionCss({ top: `-${tooltipHeight + triangleSize * 1.5}px`, left: left });
        setTriangle({
            bottom: -triangleSize,
            left: triangleLeft,
            borderLeft: `${triangleSize}px solid transparent`,
            borderRight: `${triangleSize}px solid transparent`,
            borderTop: `${triangleSize}px solid #ccc`,
        });
    }

    const toBottom = (tooltipHeight, triangleSize, triangleLeft, left) => {
        setPositionCss({ bottom: `-${tooltipHeight + triangleSize * 1.5}px`, left: left });
        setTriangle({
            top: -triangleSize,
            left: triangleLeft,
            borderLeft: `${triangleSize}px solid transparent`,
            borderRight: `${triangleSize}px solid transparent`,
            borderBottom: `${triangleSize}px solid #ccc`,
        });
    }

    const toRight = (tooltipWidth, triangleSize, triangleTop, top) => {
        setPositionCss({ right: `-${tooltipWidth + triangleSize * 1.5}px`, top: top });
        setTriangle({
            left: -triangleSize,
            top: triangleTop,
            borderTop: `${triangleSize}px solid transparent`,
            borderBottom: `${triangleSize}px solid transparent`,
            borderRight: `${triangleSize}px solid #ccc`,
        });
    }

    const toLeft = (tooltipWidth, triangleSize, triangleTop, top) => {
        setPositionCss({ left: `-${tooltipWidth + triangleSize * 1.5}px`, top: top });
        setTriangle({
            right: -triangleSize,
            top: triangleTop,
            borderTop: `${triangleSize}px solid transparent`,
            borderBottom: `${triangleSize}px solid transparent`,
            borderLeft: `${triangleSize}px solid #ccc`,
        });
    }

    useEffect(() => {
        const setPositions = () => {
            const container = refContainer?.current?.getBoundingClientRect();
            const tooltipHeight = refTooltip?.current?.offsetHeight;
            const tooltipWidth = refTooltip?.current?.offsetWidth;

            if (refTooltip?.current?.offsetHeight > minHeight) setMinHeight({ minHeight: refTooltip?.current?.offsetHeight });
            if (refTooltip?.current?.offsetWidth > minWidth) setMinWidth({ minWidth: refTooltip?.current?.offsetWidth });

            const triangleSize = 10;
            const triangleLeft = tooltipWidth / 2 - triangleSize;
            const triangleTop = tooltipHeight / 2 - triangleSize;
            const left = -(Math.abs(container.width - tooltipWidth) / 2);
            const top = -(Math.abs(container.height - tooltipHeight) / 2);

            if (position) {
                switch (position) {
                    case "top": toTop(tooltipHeight, triangleSize, triangleLeft, left); break;
                    case "bottom": toBottom(tooltipHeight, triangleSize, triangleLeft, left); break;
                    case "right": toRight(tooltipWidth, triangleSize, triangleTop, top); break;
                    default: toLeft(tooltipWidth, triangleSize, triangleTop, top); break;
                }
            }
            else {
                if (container.top - tooltipHeight >= 0) toTop(tooltipHeight, triangleSize, triangleLeft, left);
                else if (container.bottom + tooltipHeight < window.innerHeight) toBottom(tooltipHeight, triangleSize, triangleLeft, left);
                else if (container.right + tooltipWidth < window.innerWidth) toRight(tooltipWidth, triangleSize, triangleTop, top);
                else toLeft(tooltipWidth, triangleSize, triangleTop, top);
            }
        }

        setPositions();
    }, [refContainer, refTooltip, tooltip]);

    return (
        <div className={"relative"}>
            <div
                ref={refTooltip}
                className={
                    `absolute bg-gray text-black transition duration-500 rounded-sm px-[20px] py-[6px] text-center flex items-center justify-center
                    ${visible ? "opacity-100" : "opacity-0"}`}
                style={{ ...positionCss, ...minHeight, ...minWidth }}
                {...rest}
            >
                <div className="absolute border-solid w-0 h-0" style={triangle} />
                {tooltip}
            </div>
            <div
                ref={refContainer}
                onMouseOver={() => setVisible(true)}
                onMouseLeave={() => {
                    setVisible(false);
                    if (onInvisible) setTimeout(onInvisible, 500)
                }}
            >
                {children}
            </div>
        </div >
    )
}