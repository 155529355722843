import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from "react";
import { CSSTransition } from 'react-transition-group';
import "../design/BgImages.scss";

export default function ContainerWithBgImage({
    bgImg,
    bgPos = "bg-center",
    paddingTop,
    paddingBottom,
    children,
    className = "",
    containerClasses = "",
    lazyLoadNotNeeded,
    subContainerNotNeeded,
    ...rest
}) {

    const { ref, inView, entry } = useInView({
        threshold: 0.1,
        rootMargin: "500px"
    });
    const [isLoaded, setIsLoaded] = useState(lazyLoadNotNeeded ? true : false);

    useEffect(() => {
        if (inView) setIsLoaded(true);
    }, [inView]);

    return (
        <CSSTransition in={isLoaded} timeout={1000} classNames="fade">
            <div ref={ref} className={`bg-cover ${bgPos} ${containerClasses} ${isLoaded ? bgImg : ""}`}>
                <div
                    className={`${!subContainerNotNeeded ? "container mx-auto px-5" : ""} flex justify-center ${className}`}
                    style={{ paddingTop: paddingTop !== undefined ? paddingTop : 100, paddingBottom: paddingBottom !== undefined ? paddingBottom : 150 }}
                    {...rest}
                >
                    {children}
                </div>
            </div>
        </CSSTransition>
    )
}