export default function CustomLink({
    className = "",
    children,
    ...rest
}) {
    return (
        <a
            className={`text-peach hover:text-peachdark focus:text-peachlight font-medium transition duration-300 ${className}`}
            {...rest}
        >
            {children}
        </a>
    )
}